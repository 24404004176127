import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import Cordialsa1 from "../../../images/uploads/cordialsa/cordialsa-1.png"
import Cordialsa2 from "../../../images/uploads/cordialsa/cordialsa-2.png"
import Cordialsa3 from "../../../images/uploads/cordialsa/cordialsa-3.png"
import Cordialsa4 from "../../../images/uploads/cordialsa/cordialsa-4.png"
import Cordialsa5 from "../../../images/uploads/cordialsa/cordialsa-5.png"
import Cordialsa6 from "../../../images/uploads/cordialsa/cordialsa-6.png"
import Cordialsa7 from "../../../images/uploads/cordialsa/cordialsa-7.png"
import Cordialsa8 from "../../../images/uploads/cordialsa/cordialsa-8.png"
import Cordialsa9 from "../../../images/uploads/cordialsa/cordialsa-9.png"
import Cordialsa10 from "../../../images/uploads/cordialsa/cordialsa-10.png"
import Cordialsa11 from "../../../images/uploads/cordialsa/cordialsa-11.png"
import Cordialsa12 from "../../../images/uploads/cordialsa/cordialsa-12.png"
import Cordialsa13 from "../../../images/uploads/cordialsa/cordialsa-13.png"
import Cordialsa14 from "../../../images/uploads/cordialsa/cordialsa-14.png"
import Cordialsa15 from "../../../images/uploads/cordialsa/cordialsa-15.png"
import Cordialsa16 from "../../../images/uploads/cordialsa/cordialsa-16.png"
import Cordialsa17 from "../../../images/uploads/cordialsa/cordialsa-17.png"
import Cordialsa18 from "../../../images/uploads/cordialsa/cordialsa-18.png"
import Cordialsa19 from "../../../images/uploads/cordialsa/cordialsa-19.png"
import Cordialsa20 from "../../../images/uploads/cordialsa/cordialsa-20.png"
import Cordialsa21 from "../../../images/uploads/cordialsa/cordialsa-21.png"
import Cordialsa22 from "../../../images/uploads/cordialsa/cordialsa-22.png"
import Cordialsa23 from "../../../images/uploads/cordialsa/cordialsa-23.png"
import Cordialsa25 from "../../../images/uploads/cordialsa/cordialsa-25.png"
import Cordialsa26 from "../../../images/uploads/cordialsa/cordialsa-26.png"
import Cordialsa27 from "../../../images/uploads/cordialsa/cordialsa-27.png"
import Cordialsa28 from "../../../images/uploads/cordialsa/cordialsa-28.png"



import addcs1 from "../../../images/uploads/decodeup-Frattire-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-wacom-additional-case-study.png"

const Cordialsa = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang
  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Cordialsa"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Digital rebranding for optimized customer engagement
                </h4>
                <h1 className="mb-2 lg:mb-2 xl:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Food Company eCommerce Store
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  Our client's rep, Camila, approached us with the task of
                  redesigning the Cordialsa site. This project was more than
                  needing a new look - it also included making SEO changes to
                  draw new customers and a layout change that would make
                  navigation more conducive to longer browning times and
                  increase sales.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Camila
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      USA
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      UI/UX Design, Web Design, Development, Maintenance and
                      Support
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Shopify
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Business to Business
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="flex flex-wrap md:flex-nowrap gap-6">
              <div className="w-1/3 hidden md:block ">
                <img
                  className="mb-8 w-full object-cover"
                  src={Cordialsa1}
                  alt="Cordialsa Decodeup Technologies"
                />
                <img
                  className="w-full object-cover"
                  src={Cordialsa2}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
              <div className="w-full md:w-2/3 ">
                <img
                  className="w-full h-full object-cover"
                  src={Cordialsa3}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* intro section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="flex flex-wrap md:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="order-2 md:order-1 w-full md:w-1/2">
                <img
                  className="mx-auto w-full h-full object-contain object-top"
                  src={Cordialsa4}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
              <div className="order-1 md:order-2 w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Webpages that draw conversions
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Let's be honest: rebrands are never easy. There's a delicate
                  balance between shocking your audience and becoming
                  unrecognizable. We worked with Cordialsa to craft a marketing
                  plan that would help them transition to a new look without
                  incident.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The client wanted a site that was easy on the eyes and would
                  encourage users to stay as long as possible, visit each
                  section and make consistent purchases.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* intro bold section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap items-center">
              <div className="p-8 lg:p-12 xl:p-20 w-full md:w-5/65 cor-blue">
                <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                  From home page to order confirmation, this project relied on
                  visual innovation and extensive SEO knowledge to create a new
                  look that delivered for the company's bottom line.
                </h3>
              </div>
              <div className="w-full md:w-5/35">
                <img
                  className="mx-auto w-full h-full object-cover"
                  src={Cordialsa5}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>
        {/* challenges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 lg:gap-16 xl:items-center">
              <div className="w-full lg:w-1/2 hidden md:block">
                <img
                  className="mx-auto w-full h-full object-contain object-top"
                  src={Cordialsa6}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
              <div className="w-full lg:w-1/2">
                <h2 className="mb-3 lg:mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h2 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  For this client, their existing webpage was leading to sales
                  and looked decent. But they were missing that special
                  something to take their business to the next level.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  In a situation like this, it's a challenge to add something of
                  greater value to the business. We took time to study the
                  customer base and brainstorm layouts that were original and
                  user-friendly.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  One of the key parameters for our web design was to create a
                  new site that could work within the current SEO and serve as
                  an asset to their current content.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  With this in mind, we set out to see how we could add
                  additional data to the site display.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Banner Challenges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="mx-auto">
            <img
              className="mx-auto w-full h-full object-cover "
              src={Cordialsa7}
              alt="Cordialsa Decodeup Technologies"
            />
          </div>
        </section>

        {/* solutions */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="w-full mb-6 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            <div className="flex flex-wrap md:flex-nowrap gap-8 md:gap-8 lg:gap-16 relative z-1">
              <div className="lg:pt-8 w-full md:w-2/5">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Understanding is key to success
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We never offer basic solutions.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Every single design we create is 100% personalized to the
                  needs of your business, with your audience in mind. That's why
                  we spend so much time in discovery getting to know you and
                  your customers.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Then, we roll up our sleeves and get to work.
                </p>
              </div>
              <div className="w-full md:w-3/5 md:max-w-7-36">
                <img
                  className="mx-auto w-full h-full object-cover "
                  src={Cordialsa8}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
            </div>
            <div className="mt-10 lg:-mt-16 xl:-mt-2-40 flex flex-wrap md:flex-nowrap gap-8 md:gap-8 lg:gap-16 lg:items-end">
              <div className="lg::pb-8 md:order-2 w-full md:w-2/5">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Minimalist approach
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  For Cardialsa we drafted new pages that followed a minimalist
                  approach to design. We took into account the competition and
                  major sites in the same market to take stock of what works and
                  what doesn't.
                </p>

                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The result was an advanced UX/UI interface that was proven to
                  resonate with customers and clearly communicated the brand.
                  Our team completed a comprehensive SEO analysis to improve
                  keywords and phrases.
                </p>
              </div>
              <div className="md:order-1 w-full md:w-3/5 md:max-w-7-36">
                <img
                  className="mx-auto w-full h-full object-contain object-top"
                  src={Cordialsa9}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Web design grounded in experience */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="flex flex-wrap md:flex-nowrap gap-4 md:gap-8 lg:gap-16">
              <div className="w-full md:w-1/3 lg:w-2/5 max-w-3-84">
                <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Web design grounded in experience
                </h3>
              </div>
              <div className="w-full md:w-2/3 lg:w-7/10">
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  At DecodeUp, the number one goal is to provide every client
                  with a top-notch experience. We want everyone to have the best
                  digital space in their market. That's why we analyze
                  everything from your customers to your competitors to the
                  industry to inform our original designs for a custom
                  experience users will never forget.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Cordialsa brought us a wishlist and we were happy to check off
                  every single item.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* typeface */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="mb-8 lg:mb-12 flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-2/5 max-w-480">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & <br className="hidden sm:block" />
                  Colors
                </h2>
              </div>
              <div className="w-full sm:w-3/5 cord-typo text-term-primary">
                <h4 className="mb-2 lg:mb-8 text-term-primary">Montserrat</h4>
                <h5 className="mb-2 lg:mb-6 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h6 className="text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h6>
              </div>
            </div>
            <div className="mb-12 flex flex-wrap sm:flex-nowrap cordi-color">
              <div className="p-4 lg:p-8 w-full sm:w-3/5 cordi-co-1">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center md:text-left">
                  #004B7F
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/5 lg:max-w-240 cordi-co-2">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #f04f36
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/5 lg:max-w-240 cordi-co-3">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #1D1D1D
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Result */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="flex flex-wrap md:flex-nowrap gap-8 md:gap-8 lg:gap-16 xl:items-center">
              <div className="md:w-3/5">
                <h2 className="mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Result
                </h2>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We were able to deliver an attractive site that was intuitive
                  for users to navigate and most importantly, simple for their
                  team to update. The results were increased sales and a happy
                  client.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Camila and the Cordialsa team loved the designs and were happy
                  with the look, feel and results of the finished product. The
                  project was the perfect example that even successful websites
                  are never done. There is always an opportunity to improve and
                  optimize your work, which is exactly what we were able to do
                  for this client.
                </p>
                <div className="mt-6 w-full">
                  <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    How can we help you to improve your digital footprint? If
                    your site is in need of a touch-up or renovation,trust
                    DecodeUp to help you.
                  </h3>
                </div>
              </div>
              <div className="w-full md:w-2/5 md:max-w-480">
                <img
                  className="mx-auto w-full h-full object-contain object-top"
                  src={Cordialsa10}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-cor mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa11}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa12}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa13}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa14}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa15}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa16}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa17}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa18}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa19}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa20}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa21}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa22}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa23}
                    alt="Cordialsa Decodeup Technologies"
                  />

                  <img
                    className="cs-carousel-image"
                    src={Cordialsa25}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa26}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa27}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={Cordialsa28}
                    alt="Cordialsa Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/custom-clothing-design-ecommerce"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Custom Clothing Design & E-commerce
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs1}
                      alt="Frattire Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/online-store-for-tablets-stylus"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Online Store for Tablets and Stylus Products
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="WacomeStore Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default Cordialsa

export const pageQuery = graphql`
query cordialsapagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/food-company-ecommerce-store.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;